@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;800;900&display=swap");
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  margin: 0;
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  margin: auto;
  width: 100%;
  flex: 1;
  background-size: cover;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2 {
  font-family: "Playfair Display", serif;
}

p {
  font-size: 1rem;
}

@media only screen and (min-width: 1024px) {
  p {
    font-size: 1.125rem;
  }
}
.keyboard {
  margin: 1rem auto;
}
.keyboard .key-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.keyboard .key-row span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 1.6em;
  height: 2.25em;
  background-color: #f7f7f7;
  color: #1a1a1a;
  font-weight: 600;
  border-radius: 4px;
}
.keyboard .key-row span.enter:hover {
  background-color: #bda;
}
.keyboard .key-row span.enter.stroke {
  border: solid 3px rgb(67, 155, 9);
}
.keyboard .key-row span.delete:hover {
  background-color: #fab;
}

@media only screen and (min-width: 600px) {
  .keyboard {
    margin: 1.5rem auto;
  }
  .keyboard .key-row {
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .keyboard .key-row span {
    font-size: 1.5rem;
    width: 1.6em;
    height: 2.25em;
  }
}
@media only screen and (min-width: 1024px) {
  .keyboard .key-row span {
    transition: all 0.05s ease;
  }
  .keyboard .key-row span:hover {
    background-color: #baf;
    cursor: pointer;
    transform: scale(1.125);
    transition: all 0.03s ease;
  }
  .keyboard .key-row span.grey {
    background-color: #666;
  }
  .keyboard .key-row span.yellow {
    background-color: #fda;
  }
  .keyboard .key-row span.green {
    background-color: #bda;
  }
}
@media only screen and (min-width: 1400px) {
  .keyboard {
    margin: 1.5rem auto;
  }
  .keyboard .key-row {
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .keyboard .key-row span {
    font-size: 1.5rem;
    width: 1.6em;
    height: 2.25em;
  }
}
@media only screen and (min-width: 1900px) {
  .keyboard {
    margin: 1.5rem auto;
  }
  .keyboard .key-row {
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .keyboard .key-row span {
    font-size: 1.75rem;
    width: 2em;
    height: 2em;
  }
}
@media only screen and (min-width: 2200px) {
  .keyboard {
    margin: 2.5rem auto;
  }
  .keyboard .key-row {
    gap: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .keyboard .key-row span {
    font-size: 2.25rem;
    width: 2em;
    height: 2em;
  }
}
#popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#popup h2 {
  font-size: 8rem;
  color: rgb(227, 149, 5);
  opacity: 0;
}
#popup h2.win {
  animation: win 0.25s ease forwards;
}
#popup h2.lose {
  animation: lose 0.25s ease forwards;
}
#popup p {
  font-size: 2rem;
  text-align: center;
}

@keyframes win {
  0% {
    transform: translateY(120px) scale(0.75);
    color: rgb(227, 149, 5);
    opacity: 0.2;
  }
  35% {
    transform: translateY(20px) scale(0.75);
    opacity: 0.7;
  }
  55% {
    transform: translateY(10px) scale(1.25);
    color: #fda;
    opacity: 1;
  }
  100% {
    transform: translateY(0px) scale(1);
    color: #bda;
    opacity: 1;
  }
}
@keyframes lose {
  0% {
    transform: translateY(120px) scale(0.75);
    color: rgb(227, 149, 5);
    opacity: 0.2;
  }
  35% {
    transform: translateY(20px) scale(0.75);
    opacity: 0.7;
  }
  55% {
    transform: translateY(10px) scale(1.25);
    color: #fab;
    opacity: 1;
  }
  100% {
    transform: translateY(0px) scale(1);
    color: rgb(221, 77, 106);
    opacity: 1;
  }
}
.word-sign {
  position: absolute;
  font-size: 1.75rem;
  right: 1rem;
  bottom: 1rem;
  font-family: "Playfair Display", serif;
  font-weight: 900;
}
.word-sign span {
  transform: translateY(200px);
  display: inline-block;
  opacity: 1;
  transition: opacity 0.5s ease;
}
.word-sign span.show {
  animation: slide-in 0.25s ease-out forwards;
}
@keyframes slide-in {
  0% {
    transform: translateY(200px) scale(0.5);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}
.word-sign span.hide {
  opacity: 0;
}
.word-sign span.star {
  margin: 0 0.5rem;
}

@media only screen and (max-width: 1023px) {
  .word-sign {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .word-sign {
    font-size: 2rem;
    right: 3rem;
    bottom: 3rem;
  }
}
@media only screen and (min-width: 2200px) {
  .word-sign {
    font-size: 3rem;
  }
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  height: 75px;
}
header .container h1 {
  position: relative;
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  line-height: 1;
  z-index: 2;
}
header .container .hamburger {
  position: relative;
  z-index: 2;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
header .container .hamburger:hover .hamburger-inner,
header .container .hamburger:hover .hamburger-inner::before,
header .container .hamburger:hover .hamburger-inner::after {
  background-color: #aca;
}
header .container .hamburger.is-active:hover .hamburger-inner,
header .container .hamburger.is-active:hover .hamburger-inner::before,
header .container .hamburger.is-active:hover .hamburger-inner::after {
  background-color: #fda;
}
header .container .hamburger.is-active .hamburger-inner,
header .container .hamburger.is-active .hamburger-inner::before,
header .container .hamburger.is-active .hamburger-inner::after {
  background-color: #fab;
}
header .container .hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
header .container .hamburger-box.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
header .container .hamburger-box.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
header .container .hamburger-box.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}
header .container .hamburger-box .hamburger-inner {
  display: block;
  top: 50%;
}
header .container .hamburger-box .hamburger-inner, header .container .hamburger-box .hamburger-inner::before, header .container .hamburger-box .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
header .container .hamburger-box .hamburger-inner::before, header .container .hamburger-box .hamburger-inner::after {
  content: "";
  display: block;
}
header .container .hamburger-box .hamburger-inner::before {
  top: -10px;
}
header .container .hamburger-box .hamburger-inner::after {
  bottom: -10px;
}
header .container .hamburger-box .hamburger-inner .hamburger-box {
  perspective: 80px;
}
header .container .hamburger-box .hamburger-inner .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header .container .hamburger-box .hamburger-inner .hamburger-inner::before, header .container .hamburger-box .hamburger-inner .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
header nav {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20vh;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
  display: flex;
}
header nav.active {
  visibility: visible;
  opacity: 1;
}
header nav .nav-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
header nav .nav-container .title-wrap {
  animation: pulse 12s linear infinite;
}
header nav .nav-container .title-wrap h2 {
  font-size: 2.5rem;
  animation: floaty 15s ease-out alternate infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  20% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  20.1% {
    transform: scale(1.2) translateY(-5px) translateX(5px);
  }
  20.2% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  35% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  35.1% {
    transform: scale(3) translateY(30px) translateX(0px);
  }
  35.2% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  45% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  45.1% {
    transform: scale(1.5) translateY(-40px) translateX(0px);
  }
  45.2% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  60% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  60.1% {
    transform: scale(1.2) translateY(-10px) translateX(10px);
  }
  60.2% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  65% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  65.1% {
    transform: scale(2) translateY(30px) translateX(-20px);
  }
  65.2% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  90% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  90.1% {
    transform: scale(3) translateY(-80px) translateX(25px);
  }
  90.2% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
  100% {
    transform: scale(1) translateY(0px) translateX(0px);
  }
}
@keyframes floaty {
  0% {
    transform: translateX(0px) rotate(10deg) translateY(20px);
    color: #ffffff;
  }
  20% {
    transform: translateX(-20px) rotate(-10deg) translateY(15px);
    color: #bda;
  }
  40% {
    transform: translateX(35px) rotate(-15deg) translateY(10px);
    color: #fda;
  }
  60% {
    transform: translateX(65px) rotate(-5deg) translateY(-25px);
    color: #fab;
  }
  80% {
    transform: translateX(5px) rotate(15deg) translateY(-20px);
    color: #baf;
  }
  100% {
    transform: translateX(0px) rotate(-5deg) translateY(0px);
    color: #bef;
  }
}
header nav .nav-container form fieldset {
  border: none;
}
header nav .nav-container form .wordtypes-list {
  list-style: none;
  position: relative;
  width: 100%;
}
header nav .nav-container form .wordtypes-list li {
  margin-bottom: 0.5rem;
}
header nav .nav-container form .wordtypes-list li input {
  display: none;
}
header nav .nav-container form .wordtypes-list li button {
  font-size: 1.75rem;
  background: transparent;
  border: none;
  color: #ffffff;
  transition: all 1s ease;
  cursor: pointer;
}
header nav .nav-container form .wordtypes-list li button.checked.verbs {
  transition: all 0.3s ease;
  color: #bda;
}
header nav .nav-container form .wordtypes-list li button.checked.nouns {
  transition: all 0.3s ease;
  color: #fab;
}
header nav .nav-container form .wordtypes-list li button.checked.adjectives {
  transition: all 0.3s ease;
  color: #fda;
}
header nav .nav-container form .wordtypes-list .alert-message {
  background-color: rgb(221, 77, 106);
  width: 100%;
  text-align: center;
  padding: 0.25em 0.75em;
  position: absolute;
  border-radius: 5px;
}
header nav .nav-container form .range-slider-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
header nav .nav-container form .range-slider-wrap h3 {
  font-family: "Playfair Display", serif;
  font-size: 1.75rem;
  animation: floaty02 12s ease-out alternate infinite;
}
@keyframes floaty02 {
  0% {
    transform: translateX(0px) rotate(-1deg) translateY(-1px);
    color: #ffffff;
  }
  10% {
    transform: translateX(-1px) rotate(1deg) translateY(1px);
    color: #bda;
  }
  25% {
    transform: translateX(0px) rotate(0deg) translateY(0px);
    color: #fda;
  }
  40% {
    transform: translateX(0px) rotate(-2deg) translateY(-2px);
    color: #fab;
  }
  65% {
    transform: translateX(-1px) rotate(0deg) translateY(0px);
    color: rgb(221, 77, 106);
  }
  80% {
    transform: translateX(2px) rotate(3deg) translateY(2px);
    color: #baf;
  }
  100% {
    transform: translateX(0px) rotate(0deg) translateY(0px);
    color: #bef;
  }
}
header nav .nav-container form .range-slider-wrap input {
  margin: 2rem 0 1rem;
  position: relative;
  z-index: 999;
}
header nav .nav-container form .uptoexactly {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header nav .nav-container form .uptoexactly .btn-wrap {
  height: 45px;
  width: 70%;
  overflow: hidden;
  background-color: #ffffff;
  color: #1a1a1a;
  border: solid 2px #ffffff;
  border-radius: 6px;
  position: relative;
  transition: background-color 0.3s ease;
}
header nav .nav-container form .uptoexactly .btn-wrap:hover {
  cursor: pointer;
  border: solid 2px #baf;
  background-color: #baf;
  transition: background-color 0.1s ease;
  color: #1a1a1a;
}
header nav .nav-container form .uptoexactly .btn-wrap button {
  position: absolute;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}
header nav .nav-container form .uptoexactly .btn-wrap button.upto {
  top: 60px;
  opacity: 0;
}
header nav .nav-container form .uptoexactly .btn-wrap button.upto.active {
  top: 0;
  opacity: 1;
}
header nav .nav-container form .uptoexactly .btn-wrap button.exactly {
  top: 60px;
  opacity: 0;
}
header nav .nav-container form .uptoexactly .btn-wrap button.exactly.active {
  top: 0;
  opacity: 1;
}
header nav .nav-container form .uptoexactly span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.125rem;
  font-weight: 700;
  margin-left: 1rem;
  background-color: rgba(36, 19, 103, 0.558);
  border: solid 2px #ffffff;
  color: #ffffff;
  height: 45px;
  width: 45px;
  border-radius: 5px;
}
header nav .plus-btn, header nav .minus-btn {
  background-color: #ffffff;
}
header nav .plus-btn.flash, header nav .minus-btn.flash {
  background-color: red;
}
header nav .ok-btn {
  position: absolute;
  bottom: 3rem;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: solid 2px #ffffff;
  border-radius: 5px;
  font-size: 1.75rem;
  width: 68px;
  height: 50px;
  transition: background-color 0.3s ease;
  display: none;
  font-weight: 600;
}
header nav .ok-btn.animate {
  display: inline-block;
  animation: appear 0.75s ease-out forwards;
}
@keyframes appear {
  0% {
    transform: translateY(300px) rotate(0deg);
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
  }
  85% {
    transform: translateY(0px) rotate(720deg);
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
  }
  95% {
    transform: translateY(0px) rotate(720deg);
    opacity: 1;
    background-color: #ffffff;
    color: #1a1a1a;
  }
  100% {
    transform: translateY(0px) rotate(720deg);
    opacity: 1;
    background-color: #ffffff;
    color: #1a1a1a;
  }
}

@media only screen and (min-width: 1024px) {
  header nav {
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s ease;
    visibility: hidden;
    padding: 0;
  }
  header nav.active {
    visibility: visible;
    opacity: 1;
  }
  header nav .nav-container {
    display: flex;
    flex-direction: row;
    gap: 6rem;
    margin-left: -8rem;
  }
  header nav .nav-container .title-wrap {
    animation: pulse 12s linear infinite;
  }
  header nav .nav-container .title-wrap h2 {
    font-size: 4.5rem;
    animation: floaty 15s ease-out alternate infinite;
  }
  @keyframes floaty {
    0% {
      transform: translateX(0px) rotate(10deg) translateY(50px);
      color: #ffffff;
    }
    20% {
      transform: translateX(-40px) rotate(-10deg) translateY(35px);
      color: #bda;
    }
    40% {
      transform: translateX(45px) rotate(-15deg) translateY(60px);
      color: #fda;
    }
    60% {
      transform: translateX(65px) rotate(-5deg) translateY(-50px);
      color: #fab;
    }
    80% {
      transform: translateX(-25px) rotate(15deg) translateY(-40px);
      color: #baf;
    }
    100% {
      transform: translateX(0px) rotate(-5deg) translateY(0px);
      color: #bef;
    }
  }
  header nav .nav-container .menu-wrap ul li button {
    font-size: 2.25rem;
  }
  header nav .nav-container .menu-wrap ul li button:hover {
    cursor: pointer;
  }
  header nav .ok-btn {
    right: 4vw;
    bottom: 4vw;
    font-size: 2rem;
  }
  header nav .ok-btn:hover {
    background-color: #baf !important;
    border: solid 2px #baf;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}
@media only screen and (min-width: 1440px) {
  header .container {
    padding: 2rem 2.75rem 0.5rem;
  }
  header .container h1 {
    font-size: 3rem;
  }
  header nav .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -10rem;
    width: 60%;
    max-width: 1000px;
  }
  header nav .nav-container .title-wrap h2 {
    font-size: 4.75rem;
    animation: floaty 15s ease-out alternate infinite;
  }
  header nav .nav-container .menu-wrap {
    width: 30%;
  }
  header nav .nav-container .menu-wrap form .wordtypes-list li {
    margin-bottom: 1.25em;
  }
  header nav .nav-container .menu-wrap form .wordtypes-list li button {
    font-size: 2.25rem;
  }
  header nav .nav-container .menu-wrap form .range-slider-wrap h3 {
    font-size: 2.25rem;
  }
  header nav .nav-container .menu-wrap form .uptoexactly .btn-wrap {
    height: 55px;
  }
  header nav .nav-container .menu-wrap form .uptoexactly .btn-wrap button {
    font-size: 1.25rem;
  }
  header nav .nav-container .menu-wrap form .uptoexactly span {
    font-size: 2.5rem;
    font-weight: 700;
    margin-left: 1rem;
    height: 55px;
    width: 55px;
  }
  header nav .ok-btn {
    font-size: 2.5rem;
    width: 90px;
    height: 70px;
  }
  header nav .ok-btn:hover {
    background-color: #baf !important;
    border: solid 2px #baf;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}
@media only screen and (min-width: 1900px) {
  header .container {
    padding: 2rem 2.75rem 0.5rem;
  }
  header .container h1 {
    font-size: 3rem;
  }
  header nav .nav-container {
    max-width: 1000px;
  }
  header nav .nav-container .menu-wrap {
    width: 35%;
  }
  header nav .nav-container .menu-wrap form .wordtypes-list li {
    margin-bottom: 1.25em;
  }
  header nav .nav-container .menu-wrap form .wordtypes-list li button {
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 2000px) {
  header .container h1 {
    font-size: 4.5rem;
  }
  header nav .nav-container {
    display: flex;
    flex-direction: row;
    gap: 10rem;
    margin-left: -10rem;
  }
  header nav .nav-container .title-wrap h2 {
    font-size: 5.5rem;
    animation: floaty 15s ease-out alternate infinite;
  }
  header nav .nav-container .menu-wrap ul li {
    margin-bottom: 1.25rem;
  }
  header nav .nav-container .menu-wrap ul li button {
    font-size: 2.5rem;
  }
  header nav .ok-btn {
    font-size: 2.5rem;
  }
  header nav .ok-btn:hover {
    background-color: #baf !important;
    border: solid 2px #baf;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  padding: 0 3rem;
}
footer span {
  padding: 1rem;
  font-size: 1rem;
  opacity: 0.7;
}
footer span a {
  text-decoration: underline;
}
footer .social-icon, footer .empty {
  opacity: 0.7;
  width: 25px;
  height: 25px;
}
footer .social-icon img, footer .empty img {
  width: 100%;
}

.game-wrap {
  height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;
}

.letter-grid {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.letter-grid .row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  gap: 0.3rem;
  width: 100%;
  justify-content: center;
}
.letter-grid .row .letterbox {
  border: solid 1px #777;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 1.75rem;
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
}
@keyframes flip {
  0% {
    transform: rotateX(0);
    background-color: #1a1a1a;
  }
  35% {
    background-color: #1a1a1a;
    transform: rotateX(90deg);
  }
  55% {
    background-color: var(--background-color);
    border-color: var(--border-color);
    color: var(--color);
    transform: rotateX(90deg);
  }
  100% {
    background-color: var(--background-color);
    border-color: var(--border-color);
    color: var(--color);
    transform: rotateX(0);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    background-color: #1a1a1a;
  }
}
.letter-grid .row .letterbox.grey {
  --background-color: #3d3d3d;
  --color:#ffffff;
  animation: flip 0.25s ease forwards;
}
.letter-grid .row .letterbox.yellow {
  --background-color: #fda;
  --border-color: rgb(227, 149, 5);
  --color:#1a1a1a;
  animation: flip 0.25s ease forwards;
}
.letter-grid .row .letterbox.green {
  --background-color: #bda;
  --border-color: rgb(67, 155, 9);
  --color:#1a1a1a;
  animation: flip 0.25s ease forwards;
}
.letter-grid .row .letterbox.just-typed {
  animation: bounce 0.1s ease-in-out forwards;
}

@media only screen and (min-width: 600px) {
  .letter-grid {
    gap: 0.5rem;
    margin: 1.25rem;
  }
  .letter-grid .row {
    gap: 0.5rem;
  }
  .letter-grid .row .letterbox {
    font-size: 2.25rem;
    min-height: 70px;
  }
}
@media only screen and (min-width: 1024px) {
  .letter-grid .row {
    display: flex;
  }
  .letter-grid .row .letterbox {
    font-size: 2.5rem;
    width: 70px;
    height: 70px;
  }
}
@media only screen and (min-width: 1400px) {
  .game-wrap {
    justify-content: center;
  }
  .letter-grid {
    gap: 0.75rem;
    margin: auto;
  }
  .letter-grid .row {
    display: flex;
    gap: 0.75rem;
  }
  .letter-grid .row .letterbox {
    font-size: 3.75rem;
    width: 80px;
    height: 80px;
  }
}
@media only screen and (min-width: 1900px) {
  .letter-grid {
    gap: 1rem;
  }
  .letter-grid .row {
    gap: 1rem;
  }
  .letter-grid .row .letterbox {
    font-size: 4.5rem;
    width: 85px;
    height: 85px;
  }
}
@media only screen and (min-width: 2200px) {
  .letter-grid {
    gap: 1.5rem;
  }
  .letter-grid .row {
    gap: 1.5rem;
  }
  .letter-grid .row .letterbox {
    font-size: 6.5rem;
    width: 120px;
    height: 120px;
  }
}
